import React from 'react'

const BannerLanding = (props) => (
    <section id="banner" className="style2">
        <div className="inner">
            <header className="major">
                <h1>
                    <a href="https://en.wikipedia.org/wiki/Boilerplate_code">Boilerplate code</a>
                </h1>
            </header>
            <div className="content">
                <p>Tested, production-ready boilerplates developed in Nodejs, Python and Php.</p>
            </div>
        </div>
    </section>
)

export default BannerLanding
